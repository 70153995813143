import "../../assets/index2.css";
import Le, { jsx as u, jsxs as O, Fragment as H } from "react/jsx-runtime";
import { createContext as J, forwardRef as St, useContext as Z, useState as I, useEffect as oe, useLayoutEffect as Wt, useRef as Tt, useMemo as Yt } from "react";
import { i as Ee } from "../../styled-40300750.js";
import { r as Ie } from "../../createSvgIcon-a6c25c2e.js";
import Y from "moment-timezone";
import be from "../Select/index.js";
import { getIntervalTime as Ft } from "../../utilities/datetime.js";
import { Divider as Lt, Menu as Ze } from "@mui/material";
import Et from "../../assets/icons/SeekIcon.js";
const It = "_container_wj9wd_4", Rt = "_range_wj9wd_25", jt = "_date_wj9wd_33", Ht = "_time_wj9wd_33", Bt = "_selector_wj9wd_46", At = "_seek_wj9wd_50", qt = "_hover_wj9wd_60", $t = "_nohover_wj9wd_66", Vt = "_toText_wj9wd_73", W = {
  container: It,
  range: Rt,
  date: jt,
  time: Ht,
  selector: Bt,
  seek: At,
  hover: qt,
  nohover: $t,
  toText: Vt
};
var Re = {}, Qt = Ee;
Object.defineProperty(Re, "__esModule", {
  value: !0
});
var ct = Re.default = void 0, Xt = Qt(Ie()), Gt = Le, zt = (0, Xt.default)(/* @__PURE__ */ (0, Gt.jsx)("path", {
  d: "M19 4h-1V2h-2v2H8V2H6v2H5c-1.11 0-1.99.9-1.99 2L3 20c0 1.1.89 2 2 2h14c1.1 0 2-.9 2-2V6c0-1.1-.9-2-2-2zm0 16H5V10h14v10zm0-12H5V6h14v2zm-7 5h5v5h-5z"
}), "EventOutlined");
ct = Re.default = zt;
function M(e) {
  const n = Object.prototype.toString.call(e);
  return e instanceof Date || typeof e == "object" && n === "[object Date]" ? new e.constructor(+e) : typeof e == "number" || n === "[object Number]" || typeof e == "string" || n === "[object String]" ? new Date(e) : /* @__PURE__ */ new Date(NaN);
}
function R(e, n) {
  return e instanceof Date ? new e.constructor(n) : new Date(n);
}
function F(e, n) {
  const t = M(e);
  return isNaN(n) ? R(e, NaN) : (n && t.setDate(t.getDate() + n), t);
}
function B(e, n) {
  const t = M(e);
  if (isNaN(n))
    return R(e, NaN);
  if (!n)
    return t;
  const r = t.getDate(), a = R(e, t.getTime());
  a.setMonth(t.getMonth() + n + 1, 0);
  const o = a.getDate();
  return r >= o ? a : (t.setFullYear(
    a.getFullYear(),
    a.getMonth(),
    r
  ), t);
}
const je = 6048e5, Kt = 864e5;
let Ut = {};
function de() {
  return Ut;
}
function V(e, n) {
  var s, l, d, c;
  const t = de(), r = (n == null ? void 0 : n.weekStartsOn) ?? ((l = (s = n == null ? void 0 : n.locale) == null ? void 0 : s.options) == null ? void 0 : l.weekStartsOn) ?? t.weekStartsOn ?? ((c = (d = t.locale) == null ? void 0 : d.options) == null ? void 0 : c.weekStartsOn) ?? 0, a = M(e), o = a.getDay(), i = (o < r ? 7 : 0) + o - r;
  return a.setDate(a.getDate() - i), a.setHours(0, 0, 0, 0), a;
}
function U(e) {
  return V(e, { weekStartsOn: 1 });
}
function dt(e) {
  const n = M(e), t = n.getFullYear(), r = R(e, 0);
  r.setFullYear(t + 1, 0, 4), r.setHours(0, 0, 0, 0);
  const a = U(r), o = R(e, 0);
  o.setFullYear(t, 0, 4), o.setHours(0, 0, 0, 0);
  const i = U(o);
  return n.getTime() >= a.getTime() ? t + 1 : n.getTime() >= i.getTime() ? t : t - 1;
}
function ae(e) {
  const n = M(e);
  return n.setHours(0, 0, 0, 0), n;
}
function pe(e) {
  const n = M(e), t = new Date(
    Date.UTC(
      n.getFullYear(),
      n.getMonth(),
      n.getDate(),
      n.getHours(),
      n.getMinutes(),
      n.getSeconds(),
      n.getMilliseconds()
    )
  );
  return t.setUTCFullYear(n.getFullYear()), +e - +t;
}
function $(e, n) {
  const t = ae(e), r = ae(n), a = +t - pe(t), o = +r - pe(r);
  return Math.round((a - o) / Kt);
}
function Jt(e) {
  const n = dt(e), t = R(e, 0);
  return t.setFullYear(n, 0, 4), t.setHours(0, 0, 0, 0), U(t);
}
function Ye(e, n) {
  const t = n * 7;
  return F(e, t);
}
function Zt(e, n) {
  return B(e, n * 12);
}
function en(e) {
  let n;
  return e.forEach(function(t) {
    const r = M(t);
    (n === void 0 || n < r || isNaN(Number(r))) && (n = r);
  }), n || /* @__PURE__ */ new Date(NaN);
}
function tn(e) {
  let n;
  return e.forEach((t) => {
    const r = M(t);
    (!n || n > r || isNaN(+r)) && (n = r);
  }), n || /* @__PURE__ */ new Date(NaN);
}
function L(e, n) {
  const t = ae(e), r = ae(n);
  return +t == +r;
}
function He(e) {
  return e instanceof Date || typeof e == "object" && Object.prototype.toString.call(e) === "[object Date]";
}
function nn(e) {
  if (!He(e) && typeof e != "number")
    return !1;
  const n = M(e);
  return !isNaN(Number(n));
}
function ce(e, n) {
  const t = M(e), r = M(n), a = t.getFullYear() - r.getFullYear(), o = t.getMonth() - r.getMonth();
  return a * 12 + o;
}
function rn(e, n, t) {
  const r = V(e, t), a = V(n, t), o = +r - pe(r), i = +a - pe(a);
  return Math.round((o - i) / je);
}
function Be(e) {
  const n = M(e), t = n.getMonth();
  return n.setFullYear(n.getFullYear(), t + 1, 0), n.setHours(23, 59, 59, 999), n;
}
function E(e) {
  const n = M(e);
  return n.setDate(1), n.setHours(0, 0, 0, 0), n;
}
function ft(e) {
  const n = M(e), t = R(e, 0);
  return t.setFullYear(n.getFullYear(), 0, 1), t.setHours(0, 0, 0, 0), t;
}
function Ae(e, n) {
  var s, l, d, c;
  const t = de(), r = (n == null ? void 0 : n.weekStartsOn) ?? ((l = (s = n == null ? void 0 : n.locale) == null ? void 0 : s.options) == null ? void 0 : l.weekStartsOn) ?? t.weekStartsOn ?? ((c = (d = t.locale) == null ? void 0 : d.options) == null ? void 0 : c.weekStartsOn) ?? 0, a = M(e), o = a.getDay(), i = (o < r ? -7 : 0) + 6 - (o - r);
  return a.setDate(a.getDate() + i), a.setHours(23, 59, 59, 999), a;
}
function ht(e) {
  return Ae(e, { weekStartsOn: 1 });
}
const an = {
  lessThanXSeconds: {
    one: "less than a second",
    other: "less than {{count}} seconds"
  },
  xSeconds: {
    one: "1 second",
    other: "{{count}} seconds"
  },
  halfAMinute: "half a minute",
  lessThanXMinutes: {
    one: "less than a minute",
    other: "less than {{count}} minutes"
  },
  xMinutes: {
    one: "1 minute",
    other: "{{count}} minutes"
  },
  aboutXHours: {
    one: "about 1 hour",
    other: "about {{count}} hours"
  },
  xHours: {
    one: "1 hour",
    other: "{{count}} hours"
  },
  xDays: {
    one: "1 day",
    other: "{{count}} days"
  },
  aboutXWeeks: {
    one: "about 1 week",
    other: "about {{count}} weeks"
  },
  xWeeks: {
    one: "1 week",
    other: "{{count}} weeks"
  },
  aboutXMonths: {
    one: "about 1 month",
    other: "about {{count}} months"
  },
  xMonths: {
    one: "1 month",
    other: "{{count}} months"
  },
  aboutXYears: {
    one: "about 1 year",
    other: "about {{count}} years"
  },
  xYears: {
    one: "1 year",
    other: "{{count}} years"
  },
  overXYears: {
    one: "over 1 year",
    other: "over {{count}} years"
  },
  almostXYears: {
    one: "almost 1 year",
    other: "almost {{count}} years"
  }
}, on = (e, n, t) => {
  let r;
  const a = an[e];
  return typeof a == "string" ? r = a : n === 1 ? r = a.one : r = a.other.replace("{{count}}", n.toString()), t != null && t.addSuffix ? t.comparison && t.comparison > 0 ? "in " + r : r + " ago" : r;
};
function xe(e) {
  return (n = {}) => {
    const t = n.width ? String(n.width) : e.defaultWidth;
    return e.formats[t] || e.formats[e.defaultWidth];
  };
}
const sn = {
  full: "EEEE, MMMM do, y",
  long: "MMMM do, y",
  medium: "MMM d, y",
  short: "MM/dd/yyyy"
}, un = {
  full: "h:mm:ss a zzzz",
  long: "h:mm:ss a z",
  medium: "h:mm:ss a",
  short: "h:mm a"
}, ln = {
  full: "{{date}} 'at' {{time}}",
  long: "{{date}} 'at' {{time}}",
  medium: "{{date}}, {{time}}",
  short: "{{date}}, {{time}}"
}, cn = {
  date: xe({
    formats: sn,
    defaultWidth: "full"
  }),
  time: xe({
    formats: un,
    defaultWidth: "full"
  }),
  dateTime: xe({
    formats: ln,
    defaultWidth: "full"
  })
}, dn = {
  lastWeek: "'last' eeee 'at' p",
  yesterday: "'yesterday at' p",
  today: "'today at' p",
  tomorrow: "'tomorrow at' p",
  nextWeek: "eeee 'at' p",
  other: "P"
}, fn = (e, n, t, r) => dn[e];
function se(e) {
  return (n, t) => {
    const r = t != null && t.context ? String(t.context) : "standalone";
    let a;
    if (r === "formatting" && e.formattingValues) {
      const i = e.defaultFormattingWidth || e.defaultWidth, s = t != null && t.width ? String(t.width) : i;
      a = e.formattingValues[s] || e.formattingValues[i];
    } else {
      const i = e.defaultWidth, s = t != null && t.width ? String(t.width) : e.defaultWidth;
      a = e.values[s] || e.values[i];
    }
    const o = e.argumentCallback ? e.argumentCallback(n) : n;
    return a[o];
  };
}
const hn = {
  narrow: ["B", "A"],
  abbreviated: ["BC", "AD"],
  wide: ["Before Christ", "Anno Domini"]
}, mn = {
  narrow: ["1", "2", "3", "4"],
  abbreviated: ["Q1", "Q2", "Q3", "Q4"],
  wide: ["1st quarter", "2nd quarter", "3rd quarter", "4th quarter"]
}, vn = {
  narrow: ["J", "F", "M", "A", "M", "J", "J", "A", "S", "O", "N", "D"],
  abbreviated: [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec"
  ],
  wide: [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December"
  ]
}, yn = {
  narrow: ["S", "M", "T", "W", "T", "F", "S"],
  short: ["Su", "Mo", "Tu", "We", "Th", "Fr", "Sa"],
  abbreviated: ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"],
  wide: [
    "Sunday",
    "Monday",
    "Tuesday",
    "Wednesday",
    "Thursday",
    "Friday",
    "Saturday"
  ]
}, gn = {
  narrow: {
    am: "a",
    pm: "p",
    midnight: "mi",
    noon: "n",
    morning: "morning",
    afternoon: "afternoon",
    evening: "evening",
    night: "night"
  },
  abbreviated: {
    am: "AM",
    pm: "PM",
    midnight: "midnight",
    noon: "noon",
    morning: "morning",
    afternoon: "afternoon",
    evening: "evening",
    night: "night"
  },
  wide: {
    am: "a.m.",
    pm: "p.m.",
    midnight: "midnight",
    noon: "noon",
    morning: "morning",
    afternoon: "afternoon",
    evening: "evening",
    night: "night"
  }
}, bn = {
  narrow: {
    am: "a",
    pm: "p",
    midnight: "mi",
    noon: "n",
    morning: "in the morning",
    afternoon: "in the afternoon",
    evening: "in the evening",
    night: "at night"
  },
  abbreviated: {
    am: "AM",
    pm: "PM",
    midnight: "midnight",
    noon: "noon",
    morning: "in the morning",
    afternoon: "in the afternoon",
    evening: "in the evening",
    night: "at night"
  },
  wide: {
    am: "a.m.",
    pm: "p.m.",
    midnight: "midnight",
    noon: "noon",
    morning: "in the morning",
    afternoon: "in the afternoon",
    evening: "in the evening",
    night: "at night"
  }
}, wn = (e, n) => {
  const t = Number(e), r = t % 100;
  if (r > 20 || r < 10)
    switch (r % 10) {
      case 1:
        return t + "st";
      case 2:
        return t + "nd";
      case 3:
        return t + "rd";
    }
  return t + "th";
}, pn = {
  ordinalNumber: wn,
  era: se({
    values: hn,
    defaultWidth: "wide"
  }),
  quarter: se({
    values: mn,
    defaultWidth: "wide",
    argumentCallback: (e) => e - 1
  }),
  month: se({
    values: vn,
    defaultWidth: "wide"
  }),
  day: se({
    values: yn,
    defaultWidth: "wide"
  }),
  dayPeriod: se({
    values: gn,
    defaultWidth: "wide",
    formattingValues: bn,
    defaultFormattingWidth: "wide"
  })
};
function ue(e) {
  return (n, t = {}) => {
    const r = t.width, a = r && e.matchPatterns[r] || e.matchPatterns[e.defaultMatchWidth], o = n.match(a);
    if (!o)
      return null;
    const i = o[0], s = r && e.parsePatterns[r] || e.parsePatterns[e.defaultParseWidth], l = Array.isArray(s) ? Mn(s, (f) => f.test(i)) : (
      // eslint-disable-next-line @typescript-eslint/no-explicit-any -- I challange you to fix the type
      _n(s, (f) => f.test(i))
    );
    let d;
    d = e.valueCallback ? e.valueCallback(l) : l, d = t.valueCallback ? (
      // eslint-disable-next-line @typescript-eslint/no-explicit-any -- I challange you to fix the type
      t.valueCallback(d)
    ) : d;
    const c = n.slice(i.length);
    return { value: d, rest: c };
  };
}
function _n(e, n) {
  for (const t in e)
    if (Object.prototype.hasOwnProperty.call(e, t) && n(e[t]))
      return t;
}
function Mn(e, n) {
  for (let t = 0; t < e.length; t++)
    if (n(e[t]))
      return t;
}
function Dn(e) {
  return (n, t = {}) => {
    const r = n.match(e.matchPattern);
    if (!r)
      return null;
    const a = r[0], o = n.match(e.parsePattern);
    if (!o)
      return null;
    let i = e.valueCallback ? e.valueCallback(o[0]) : o[0];
    i = t.valueCallback ? t.valueCallback(i) : i;
    const s = n.slice(a.length);
    return { value: i, rest: s };
  };
}
const kn = /^(\d+)(th|st|nd|rd)?/i, Nn = /\d+/i, xn = {
  narrow: /^(b|a)/i,
  abbreviated: /^(b\.?\s?c\.?|b\.?\s?c\.?\s?e\.?|a\.?\s?d\.?|c\.?\s?e\.?)/i,
  wide: /^(before christ|before common era|anno domini|common era)/i
}, Cn = {
  any: [/^b/i, /^(a|c)/i]
}, Pn = {
  narrow: /^[1234]/i,
  abbreviated: /^q[1234]/i,
  wide: /^[1234](th|st|nd|rd)? quarter/i
}, On = {
  any: [/1/i, /2/i, /3/i, /4/i]
}, Sn = {
  narrow: /^[jfmasond]/i,
  abbreviated: /^(jan|feb|mar|apr|may|jun|jul|aug|sep|oct|nov|dec)/i,
  wide: /^(january|february|march|april|may|june|july|august|september|october|november|december)/i
}, Wn = {
  narrow: [
    /^j/i,
    /^f/i,
    /^m/i,
    /^a/i,
    /^m/i,
    /^j/i,
    /^j/i,
    /^a/i,
    /^s/i,
    /^o/i,
    /^n/i,
    /^d/i
  ],
  any: [
    /^ja/i,
    /^f/i,
    /^mar/i,
    /^ap/i,
    /^may/i,
    /^jun/i,
    /^jul/i,
    /^au/i,
    /^s/i,
    /^o/i,
    /^n/i,
    /^d/i
  ]
}, Tn = {
  narrow: /^[smtwf]/i,
  short: /^(su|mo|tu|we|th|fr|sa)/i,
  abbreviated: /^(sun|mon|tue|wed|thu|fri|sat)/i,
  wide: /^(sunday|monday|tuesday|wednesday|thursday|friday|saturday)/i
}, Yn = {
  narrow: [/^s/i, /^m/i, /^t/i, /^w/i, /^t/i, /^f/i, /^s/i],
  any: [/^su/i, /^m/i, /^tu/i, /^w/i, /^th/i, /^f/i, /^sa/i]
}, Fn = {
  narrow: /^(a|p|mi|n|(in the|at) (morning|afternoon|evening|night))/i,
  any: /^([ap]\.?\s?m\.?|midnight|noon|(in the|at) (morning|afternoon|evening|night))/i
}, Ln = {
  any: {
    am: /^a/i,
    pm: /^p/i,
    midnight: /^mi/i,
    noon: /^no/i,
    morning: /morning/i,
    afternoon: /afternoon/i,
    evening: /evening/i,
    night: /night/i
  }
}, En = {
  ordinalNumber: Dn({
    matchPattern: kn,
    parsePattern: Nn,
    valueCallback: (e) => parseInt(e, 10)
  }),
  era: ue({
    matchPatterns: xn,
    defaultMatchWidth: "wide",
    parsePatterns: Cn,
    defaultParseWidth: "any"
  }),
  quarter: ue({
    matchPatterns: Pn,
    defaultMatchWidth: "wide",
    parsePatterns: On,
    defaultParseWidth: "any",
    valueCallback: (e) => e + 1
  }),
  month: ue({
    matchPatterns: Sn,
    defaultMatchWidth: "wide",
    parsePatterns: Wn,
    defaultParseWidth: "any"
  }),
  day: ue({
    matchPatterns: Tn,
    defaultMatchWidth: "wide",
    parsePatterns: Yn,
    defaultParseWidth: "any"
  }),
  dayPeriod: ue({
    matchPatterns: Fn,
    defaultMatchWidth: "any",
    parsePatterns: Ln,
    defaultParseWidth: "any"
  })
}, mt = {
  code: "en-US",
  formatDistance: on,
  formatLong: cn,
  formatRelative: fn,
  localize: pn,
  match: En,
  options: {
    weekStartsOn: 0,
    firstWeekContainsDate: 1
  }
};
function In(e) {
  const n = M(e);
  return $(n, ft(n)) + 1;
}
function vt(e) {
  const n = M(e), t = +U(n) - +Jt(n);
  return Math.round(t / je) + 1;
}
function yt(e, n) {
  var c, f, y, m;
  const t = M(e), r = t.getFullYear(), a = de(), o = (n == null ? void 0 : n.firstWeekContainsDate) ?? ((f = (c = n == null ? void 0 : n.locale) == null ? void 0 : c.options) == null ? void 0 : f.firstWeekContainsDate) ?? a.firstWeekContainsDate ?? ((m = (y = a.locale) == null ? void 0 : y.options) == null ? void 0 : m.firstWeekContainsDate) ?? 1, i = R(e, 0);
  i.setFullYear(r + 1, 0, o), i.setHours(0, 0, 0, 0);
  const s = V(i, n), l = R(e, 0);
  l.setFullYear(r, 0, o), l.setHours(0, 0, 0, 0);
  const d = V(l, n);
  return t.getTime() >= s.getTime() ? r + 1 : t.getTime() >= d.getTime() ? r : r - 1;
}
function Rn(e, n) {
  var s, l, d, c;
  const t = de(), r = (n == null ? void 0 : n.firstWeekContainsDate) ?? ((l = (s = n == null ? void 0 : n.locale) == null ? void 0 : s.options) == null ? void 0 : l.firstWeekContainsDate) ?? t.firstWeekContainsDate ?? ((c = (d = t.locale) == null ? void 0 : d.options) == null ? void 0 : c.firstWeekContainsDate) ?? 1, a = yt(e, n), o = R(e, 0);
  return o.setFullYear(a, 0, r), o.setHours(0, 0, 0, 0), V(o, n);
}
function gt(e, n) {
  const t = M(e), r = +V(t, n) - +Rn(t, n);
  return Math.round(r / je) + 1;
}
function k(e, n) {
  const t = e < 0 ? "-" : "", r = Math.abs(e).toString().padStart(n, "0");
  return t + r;
}
const z = {
  // Year
  y(e, n) {
    const t = e.getFullYear(), r = t > 0 ? t : 1 - t;
    return k(n === "yy" ? r % 100 : r, n.length);
  },
  // Month
  M(e, n) {
    const t = e.getMonth();
    return n === "M" ? String(t + 1) : k(t + 1, 2);
  },
  // Day of the month
  d(e, n) {
    return k(e.getDate(), n.length);
  },
  // AM or PM
  a(e, n) {
    const t = e.getHours() / 12 >= 1 ? "pm" : "am";
    switch (n) {
      case "a":
      case "aa":
        return t.toUpperCase();
      case "aaa":
        return t;
      case "aaaaa":
        return t[0];
      case "aaaa":
      default:
        return t === "am" ? "a.m." : "p.m.";
    }
  },
  // Hour [1-12]
  h(e, n) {
    return k(e.getHours() % 12 || 12, n.length);
  },
  // Hour [0-23]
  H(e, n) {
    return k(e.getHours(), n.length);
  },
  // Minute
  m(e, n) {
    return k(e.getMinutes(), n.length);
  },
  // Second
  s(e, n) {
    return k(e.getSeconds(), n.length);
  },
  // Fraction of second
  S(e, n) {
    const t = n.length, r = e.getMilliseconds(), a = Math.trunc(
      r * Math.pow(10, t - 3)
    );
    return k(a, n.length);
  }
}, re = {
  am: "am",
  pm: "pm",
  midnight: "midnight",
  noon: "noon",
  morning: "morning",
  afternoon: "afternoon",
  evening: "evening",
  night: "night"
}, et = {
  // Era
  G: function(e, n, t) {
    const r = e.getFullYear() > 0 ? 1 : 0;
    switch (n) {
      case "G":
      case "GG":
      case "GGG":
        return t.era(r, { width: "abbreviated" });
      case "GGGGG":
        return t.era(r, { width: "narrow" });
      case "GGGG":
      default:
        return t.era(r, { width: "wide" });
    }
  },
  // Year
  y: function(e, n, t) {
    if (n === "yo") {
      const r = e.getFullYear(), a = r > 0 ? r : 1 - r;
      return t.ordinalNumber(a, { unit: "year" });
    }
    return z.y(e, n);
  },
  // Local week-numbering year
  Y: function(e, n, t, r) {
    const a = yt(e, r), o = a > 0 ? a : 1 - a;
    if (n === "YY") {
      const i = o % 100;
      return k(i, 2);
    }
    return n === "Yo" ? t.ordinalNumber(o, { unit: "year" }) : k(o, n.length);
  },
  // ISO week-numbering year
  R: function(e, n) {
    const t = dt(e);
    return k(t, n.length);
  },
  // Extended year. This is a single number designating the year of this calendar system.
  // The main difference between `y` and `u` localizers are B.C. years:
  // | Year | `y` | `u` |
  // |------|-----|-----|
  // | AC 1 |   1 |   1 |
  // | BC 1 |   1 |   0 |
  // | BC 2 |   2 |  -1 |
  // Also `yy` always returns the last two digits of a year,
  // while `uu` pads single digit years to 2 characters and returns other years unchanged.
  u: function(e, n) {
    const t = e.getFullYear();
    return k(t, n.length);
  },
  // Quarter
  Q: function(e, n, t) {
    const r = Math.ceil((e.getMonth() + 1) / 3);
    switch (n) {
      case "Q":
        return String(r);
      case "QQ":
        return k(r, 2);
      case "Qo":
        return t.ordinalNumber(r, { unit: "quarter" });
      case "QQQ":
        return t.quarter(r, {
          width: "abbreviated",
          context: "formatting"
        });
      case "QQQQQ":
        return t.quarter(r, {
          width: "narrow",
          context: "formatting"
        });
      case "QQQQ":
      default:
        return t.quarter(r, {
          width: "wide",
          context: "formatting"
        });
    }
  },
  // Stand-alone quarter
  q: function(e, n, t) {
    const r = Math.ceil((e.getMonth() + 1) / 3);
    switch (n) {
      case "q":
        return String(r);
      case "qq":
        return k(r, 2);
      case "qo":
        return t.ordinalNumber(r, { unit: "quarter" });
      case "qqq":
        return t.quarter(r, {
          width: "abbreviated",
          context: "standalone"
        });
      case "qqqqq":
        return t.quarter(r, {
          width: "narrow",
          context: "standalone"
        });
      case "qqqq":
      default:
        return t.quarter(r, {
          width: "wide",
          context: "standalone"
        });
    }
  },
  // Month
  M: function(e, n, t) {
    const r = e.getMonth();
    switch (n) {
      case "M":
      case "MM":
        return z.M(e, n);
      case "Mo":
        return t.ordinalNumber(r + 1, { unit: "month" });
      case "MMM":
        return t.month(r, {
          width: "abbreviated",
          context: "formatting"
        });
      case "MMMMM":
        return t.month(r, {
          width: "narrow",
          context: "formatting"
        });
      case "MMMM":
      default:
        return t.month(r, { width: "wide", context: "formatting" });
    }
  },
  // Stand-alone month
  L: function(e, n, t) {
    const r = e.getMonth();
    switch (n) {
      case "L":
        return String(r + 1);
      case "LL":
        return k(r + 1, 2);
      case "Lo":
        return t.ordinalNumber(r + 1, { unit: "month" });
      case "LLL":
        return t.month(r, {
          width: "abbreviated",
          context: "standalone"
        });
      case "LLLLL":
        return t.month(r, {
          width: "narrow",
          context: "standalone"
        });
      case "LLLL":
      default:
        return t.month(r, { width: "wide", context: "standalone" });
    }
  },
  // Local week of year
  w: function(e, n, t, r) {
    const a = gt(e, r);
    return n === "wo" ? t.ordinalNumber(a, { unit: "week" }) : k(a, n.length);
  },
  // ISO week of year
  I: function(e, n, t) {
    const r = vt(e);
    return n === "Io" ? t.ordinalNumber(r, { unit: "week" }) : k(r, n.length);
  },
  // Day of the month
  d: function(e, n, t) {
    return n === "do" ? t.ordinalNumber(e.getDate(), { unit: "date" }) : z.d(e, n);
  },
  // Day of year
  D: function(e, n, t) {
    const r = In(e);
    return n === "Do" ? t.ordinalNumber(r, { unit: "dayOfYear" }) : k(r, n.length);
  },
  // Day of week
  E: function(e, n, t) {
    const r = e.getDay();
    switch (n) {
      case "E":
      case "EE":
      case "EEE":
        return t.day(r, {
          width: "abbreviated",
          context: "formatting"
        });
      case "EEEEE":
        return t.day(r, {
          width: "narrow",
          context: "formatting"
        });
      case "EEEEEE":
        return t.day(r, {
          width: "short",
          context: "formatting"
        });
      case "EEEE":
      default:
        return t.day(r, {
          width: "wide",
          context: "formatting"
        });
    }
  },
  // Local day of week
  e: function(e, n, t, r) {
    const a = e.getDay(), o = (a - r.weekStartsOn + 8) % 7 || 7;
    switch (n) {
      case "e":
        return String(o);
      case "ee":
        return k(o, 2);
      case "eo":
        return t.ordinalNumber(o, { unit: "day" });
      case "eee":
        return t.day(a, {
          width: "abbreviated",
          context: "formatting"
        });
      case "eeeee":
        return t.day(a, {
          width: "narrow",
          context: "formatting"
        });
      case "eeeeee":
        return t.day(a, {
          width: "short",
          context: "formatting"
        });
      case "eeee":
      default:
        return t.day(a, {
          width: "wide",
          context: "formatting"
        });
    }
  },
  // Stand-alone local day of week
  c: function(e, n, t, r) {
    const a = e.getDay(), o = (a - r.weekStartsOn + 8) % 7 || 7;
    switch (n) {
      case "c":
        return String(o);
      case "cc":
        return k(o, n.length);
      case "co":
        return t.ordinalNumber(o, { unit: "day" });
      case "ccc":
        return t.day(a, {
          width: "abbreviated",
          context: "standalone"
        });
      case "ccccc":
        return t.day(a, {
          width: "narrow",
          context: "standalone"
        });
      case "cccccc":
        return t.day(a, {
          width: "short",
          context: "standalone"
        });
      case "cccc":
      default:
        return t.day(a, {
          width: "wide",
          context: "standalone"
        });
    }
  },
  // ISO day of week
  i: function(e, n, t) {
    const r = e.getDay(), a = r === 0 ? 7 : r;
    switch (n) {
      case "i":
        return String(a);
      case "ii":
        return k(a, n.length);
      case "io":
        return t.ordinalNumber(a, { unit: "day" });
      case "iii":
        return t.day(r, {
          width: "abbreviated",
          context: "formatting"
        });
      case "iiiii":
        return t.day(r, {
          width: "narrow",
          context: "formatting"
        });
      case "iiiiii":
        return t.day(r, {
          width: "short",
          context: "formatting"
        });
      case "iiii":
      default:
        return t.day(r, {
          width: "wide",
          context: "formatting"
        });
    }
  },
  // AM or PM
  a: function(e, n, t) {
    const a = e.getHours() / 12 >= 1 ? "pm" : "am";
    switch (n) {
      case "a":
      case "aa":
        return t.dayPeriod(a, {
          width: "abbreviated",
          context: "formatting"
        });
      case "aaa":
        return t.dayPeriod(a, {
          width: "abbreviated",
          context: "formatting"
        }).toLowerCase();
      case "aaaaa":
        return t.dayPeriod(a, {
          width: "narrow",
          context: "formatting"
        });
      case "aaaa":
      default:
        return t.dayPeriod(a, {
          width: "wide",
          context: "formatting"
        });
    }
  },
  // AM, PM, midnight, noon
  b: function(e, n, t) {
    const r = e.getHours();
    let a;
    switch (r === 12 ? a = re.noon : r === 0 ? a = re.midnight : a = r / 12 >= 1 ? "pm" : "am", n) {
      case "b":
      case "bb":
        return t.dayPeriod(a, {
          width: "abbreviated",
          context: "formatting"
        });
      case "bbb":
        return t.dayPeriod(a, {
          width: "abbreviated",
          context: "formatting"
        }).toLowerCase();
      case "bbbbb":
        return t.dayPeriod(a, {
          width: "narrow",
          context: "formatting"
        });
      case "bbbb":
      default:
        return t.dayPeriod(a, {
          width: "wide",
          context: "formatting"
        });
    }
  },
  // in the morning, in the afternoon, in the evening, at night
  B: function(e, n, t) {
    const r = e.getHours();
    let a;
    switch (r >= 17 ? a = re.evening : r >= 12 ? a = re.afternoon : r >= 4 ? a = re.morning : a = re.night, n) {
      case "B":
      case "BB":
      case "BBB":
        return t.dayPeriod(a, {
          width: "abbreviated",
          context: "formatting"
        });
      case "BBBBB":
        return t.dayPeriod(a, {
          width: "narrow",
          context: "formatting"
        });
      case "BBBB":
      default:
        return t.dayPeriod(a, {
          width: "wide",
          context: "formatting"
        });
    }
  },
  // Hour [1-12]
  h: function(e, n, t) {
    if (n === "ho") {
      let r = e.getHours() % 12;
      return r === 0 && (r = 12), t.ordinalNumber(r, { unit: "hour" });
    }
    return z.h(e, n);
  },
  // Hour [0-23]
  H: function(e, n, t) {
    return n === "Ho" ? t.ordinalNumber(e.getHours(), { unit: "hour" }) : z.H(e, n);
  },
  // Hour [0-11]
  K: function(e, n, t) {
    const r = e.getHours() % 12;
    return n === "Ko" ? t.ordinalNumber(r, { unit: "hour" }) : k(r, n.length);
  },
  // Hour [1-24]
  k: function(e, n, t) {
    let r = e.getHours();
    return r === 0 && (r = 24), n === "ko" ? t.ordinalNumber(r, { unit: "hour" }) : k(r, n.length);
  },
  // Minute
  m: function(e, n, t) {
    return n === "mo" ? t.ordinalNumber(e.getMinutes(), { unit: "minute" }) : z.m(e, n);
  },
  // Second
  s: function(e, n, t) {
    return n === "so" ? t.ordinalNumber(e.getSeconds(), { unit: "second" }) : z.s(e, n);
  },
  // Fraction of second
  S: function(e, n) {
    return z.S(e, n);
  },
  // Timezone (ISO-8601. If offset is 0, output is always `'Z'`)
  X: function(e, n, t) {
    const r = e.getTimezoneOffset();
    if (r === 0)
      return "Z";
    switch (n) {
      case "X":
        return nt(r);
      case "XXXX":
      case "XX":
        return K(r);
      case "XXXXX":
      case "XXX":
      default:
        return K(r, ":");
    }
  },
  // Timezone (ISO-8601. If offset is 0, output is `'+00:00'` or equivalent)
  x: function(e, n, t) {
    const r = e.getTimezoneOffset();
    switch (n) {
      case "x":
        return nt(r);
      case "xxxx":
      case "xx":
        return K(r);
      case "xxxxx":
      case "xxx":
      default:
        return K(r, ":");
    }
  },
  // Timezone (GMT)
  O: function(e, n, t) {
    const r = e.getTimezoneOffset();
    switch (n) {
      case "O":
      case "OO":
      case "OOO":
        return "GMT" + tt(r, ":");
      case "OOOO":
      default:
        return "GMT" + K(r, ":");
    }
  },
  // Timezone (specific non-location)
  z: function(e, n, t) {
    const r = e.getTimezoneOffset();
    switch (n) {
      case "z":
      case "zz":
      case "zzz":
        return "GMT" + tt(r, ":");
      case "zzzz":
      default:
        return "GMT" + K(r, ":");
    }
  },
  // Seconds timestamp
  t: function(e, n, t) {
    const r = Math.trunc(e.getTime() / 1e3);
    return k(r, n.length);
  },
  // Milliseconds timestamp
  T: function(e, n, t) {
    const r = e.getTime();
    return k(r, n.length);
  }
};
function tt(e, n = "") {
  const t = e > 0 ? "-" : "+", r = Math.abs(e), a = Math.trunc(r / 60), o = r % 60;
  return o === 0 ? t + String(a) : t + String(a) + n + k(o, 2);
}
function nt(e, n) {
  return e % 60 === 0 ? (e > 0 ? "-" : "+") + k(Math.abs(e) / 60, 2) : K(e, n);
}
function K(e, n = "") {
  const t = e > 0 ? "-" : "+", r = Math.abs(e), a = k(Math.trunc(r / 60), 2), o = k(r % 60, 2);
  return t + a + n + o;
}
const rt = (e, n) => {
  switch (e) {
    case "P":
      return n.date({ width: "short" });
    case "PP":
      return n.date({ width: "medium" });
    case "PPP":
      return n.date({ width: "long" });
    case "PPPP":
    default:
      return n.date({ width: "full" });
  }
}, bt = (e, n) => {
  switch (e) {
    case "p":
      return n.time({ width: "short" });
    case "pp":
      return n.time({ width: "medium" });
    case "ppp":
      return n.time({ width: "long" });
    case "pppp":
    default:
      return n.time({ width: "full" });
  }
}, jn = (e, n) => {
  const t = e.match(/(P+)(p+)?/) || [], r = t[1], a = t[2];
  if (!a)
    return rt(e, n);
  let o;
  switch (r) {
    case "P":
      o = n.dateTime({ width: "short" });
      break;
    case "PP":
      o = n.dateTime({ width: "medium" });
      break;
    case "PPP":
      o = n.dateTime({ width: "long" });
      break;
    case "PPPP":
    default:
      o = n.dateTime({ width: "full" });
      break;
  }
  return o.replace("{{date}}", rt(r, n)).replace("{{time}}", bt(a, n));
}, Hn = {
  p: bt,
  P: jn
}, Bn = /^D+$/, An = /^Y+$/, qn = ["D", "DD", "YY", "YYYY"];
function $n(e) {
  return Bn.test(e);
}
function Vn(e) {
  return An.test(e);
}
function Qn(e, n, t) {
  const r = Xn(e, n, t);
  if (console.warn(r), qn.includes(e))
    throw new RangeError(r);
}
function Xn(e, n, t) {
  const r = e[0] === "Y" ? "years" : "days of the month";
  return `Use \`${e.toLowerCase()}\` instead of \`${e}\` (in \`${n}\`) for formatting ${r} to the input \`${t}\`; see: https://github.com/date-fns/date-fns/blob/master/docs/unicodeTokens.md`;
}
const Gn = /[yYQqMLwIdDecihHKkms]o|(\w)\1*|''|'(''|[^'])+('|$)|./g, zn = /P+p+|P+|p+|''|'(''|[^'])+('|$)|./g, Kn = /^'([^]*?)'?$/, Un = /''/g, Jn = /[a-zA-Z]/;
function ee(e, n, t) {
  var c, f, y, m, g, _, b, D;
  const r = de(), a = (t == null ? void 0 : t.locale) ?? r.locale ?? mt, o = (t == null ? void 0 : t.firstWeekContainsDate) ?? ((f = (c = t == null ? void 0 : t.locale) == null ? void 0 : c.options) == null ? void 0 : f.firstWeekContainsDate) ?? r.firstWeekContainsDate ?? ((m = (y = r.locale) == null ? void 0 : y.options) == null ? void 0 : m.firstWeekContainsDate) ?? 1, i = (t == null ? void 0 : t.weekStartsOn) ?? ((_ = (g = t == null ? void 0 : t.locale) == null ? void 0 : g.options) == null ? void 0 : _.weekStartsOn) ?? r.weekStartsOn ?? ((D = (b = r.locale) == null ? void 0 : b.options) == null ? void 0 : D.weekStartsOn) ?? 0, s = M(e);
  if (!nn(s))
    throw new RangeError("Invalid time value");
  let l = n.match(zn).map((p) => {
    const x = p[0];
    if (x === "p" || x === "P") {
      const q = Hn[x];
      return q(p, a.formatLong);
    }
    return p;
  }).join("").match(Gn).map((p) => {
    if (p === "''")
      return { isToken: !1, value: "'" };
    const x = p[0];
    if (x === "'")
      return { isToken: !1, value: Zn(p) };
    if (et[x])
      return { isToken: !0, value: p };
    if (x.match(Jn))
      throw new RangeError(
        "Format string contains an unescaped latin alphabet character `" + x + "`"
      );
    return { isToken: !1, value: p };
  });
  a.localize.preprocessor && (l = a.localize.preprocessor(s, l));
  const d = {
    firstWeekContainsDate: o,
    weekStartsOn: i,
    locale: a
  };
  return l.map((p) => {
    if (!p.isToken)
      return p.value;
    const x = p.value;
    (!(t != null && t.useAdditionalWeekYearTokens) && Vn(x) || !(t != null && t.useAdditionalDayOfYearTokens) && $n(x)) && Qn(x, n, String(e));
    const q = et[x[0]];
    return q(s, x, a.localize, d);
  }).join("");
}
function Zn(e) {
  const n = e.match(Kn);
  return n ? n[1].replace(Un, "'") : e;
}
function er(e) {
  const n = M(e), t = n.getFullYear(), r = n.getMonth(), a = R(e, 0);
  return a.setFullYear(t, r + 1, 0), a.setHours(0, 0, 0, 0), a.getDate();
}
function tr(e) {
  return Math.trunc(+M(e) / 1e3);
}
function nr(e) {
  const n = M(e), t = n.getMonth();
  return n.setFullYear(n.getFullYear(), t + 1, 0), n.setHours(0, 0, 0, 0), n;
}
function rr(e, n) {
  return rn(
    nr(e),
    E(e),
    n
  ) + 1;
}
function Fe(e, n) {
  const t = M(e), r = M(n);
  return t.getTime() > r.getTime();
}
function wt(e, n) {
  const t = M(e), r = M(n);
  return +t < +r;
}
function qe(e, n) {
  const t = M(e), r = M(n);
  return t.getFullYear() === r.getFullYear() && t.getMonth() === r.getMonth();
}
function ar(e, n) {
  const t = M(e), r = M(n);
  return t.getFullYear() === r.getFullYear();
}
function Ce(e, n) {
  return F(e, -n);
}
function Pe(e, n) {
  const t = M(e), r = t.getFullYear(), a = t.getDate(), o = R(e, 0);
  o.setFullYear(r, n, 15), o.setHours(0, 0, 0, 0);
  const i = er(o);
  return t.setMonth(n, Math.min(a, i)), t;
}
function at(e, n) {
  const t = M(e);
  return isNaN(+t) ? R(e, NaN) : (t.setFullYear(n), t);
}
var w = function() {
  return w = Object.assign || function(n) {
    for (var t, r = 1, a = arguments.length; r < a; r++) {
      t = arguments[r];
      for (var o in t)
        Object.prototype.hasOwnProperty.call(t, o) && (n[o] = t[o]);
    }
    return n;
  }, w.apply(this, arguments);
};
function or(e, n) {
  var t = {};
  for (var r in e)
    Object.prototype.hasOwnProperty.call(e, r) && n.indexOf(r) < 0 && (t[r] = e[r]);
  if (e != null && typeof Object.getOwnPropertySymbols == "function")
    for (var a = 0, r = Object.getOwnPropertySymbols(e); a < r.length; a++)
      n.indexOf(r[a]) < 0 && Object.prototype.propertyIsEnumerable.call(e, r[a]) && (t[r[a]] = e[r[a]]);
  return t;
}
function pt(e, n, t) {
  if (t || arguments.length === 2)
    for (var r = 0, a = n.length, o; r < a; r++)
      (o || !(r in n)) && (o || (o = Array.prototype.slice.call(n, 0, r)), o[r] = n[r]);
  return e.concat(o || Array.prototype.slice.call(n));
}
function fe(e) {
  return e.mode === "multiple";
}
function he(e) {
  return e.mode === "range";
}
function Me(e) {
  return e.mode === "single";
}
var ir = {
  root: "rdp",
  multiple_months: "rdp-multiple_months",
  with_weeknumber: "rdp-with_weeknumber",
  vhidden: "rdp-vhidden",
  button_reset: "rdp-button_reset",
  button: "rdp-button",
  caption: "rdp-caption",
  caption_start: "rdp-caption_start",
  caption_end: "rdp-caption_end",
  caption_between: "rdp-caption_between",
  caption_label: "rdp-caption_label",
  caption_dropdowns: "rdp-caption_dropdowns",
  dropdown: "rdp-dropdown",
  dropdown_month: "rdp-dropdown_month",
  dropdown_year: "rdp-dropdown_year",
  dropdown_icon: "rdp-dropdown_icon",
  months: "rdp-months",
  month: "rdp-month",
  table: "rdp-table",
  tbody: "rdp-tbody",
  tfoot: "rdp-tfoot",
  head: "rdp-head",
  head_row: "rdp-head_row",
  head_cell: "rdp-head_cell",
  nav: "rdp-nav",
  nav_button: "rdp-nav_button",
  nav_button_previous: "rdp-nav_button_previous",
  nav_button_next: "rdp-nav_button_next",
  nav_icon: "rdp-nav_icon",
  row: "rdp-row",
  weeknumber: "rdp-weeknumber",
  cell: "rdp-cell",
  day: "rdp-day",
  day_today: "rdp-day_today",
  day_outside: "rdp-day_outside",
  day_selected: "rdp-day_selected",
  day_disabled: "rdp-day_disabled",
  day_hidden: "rdp-day_hidden",
  day_range_start: "rdp-day_range_start",
  day_range_end: "rdp-day_range_end",
  day_range_middle: "rdp-day_range_middle"
};
function sr(e, n) {
  return ee(e, "LLLL y", n);
}
function ur(e, n) {
  return ee(e, "d", n);
}
function lr(e, n) {
  return ee(e, "LLLL", n);
}
function cr(e) {
  return "".concat(e);
}
function dr(e, n) {
  return ee(e, "cccccc", n);
}
function fr(e, n) {
  return ee(e, "yyyy", n);
}
var hr = /* @__PURE__ */ Object.freeze({
  __proto__: null,
  formatCaption: sr,
  formatDay: ur,
  formatMonthCaption: lr,
  formatWeekNumber: cr,
  formatWeekdayName: dr,
  formatYearCaption: fr
}), mr = function(e, n, t) {
  return ee(e, "do MMMM (EEEE)", t);
}, vr = function() {
  return "Month: ";
}, yr = function() {
  return "Go to next month";
}, gr = function() {
  return "Go to previous month";
}, br = function(e, n) {
  return ee(e, "cccc", n);
}, wr = function(e) {
  return "Week n. ".concat(e);
}, pr = function() {
  return "Year: ";
}, _r = /* @__PURE__ */ Object.freeze({
  __proto__: null,
  labelDay: mr,
  labelMonthDropdown: vr,
  labelNext: yr,
  labelPrevious: gr,
  labelWeekNumber: wr,
  labelWeekday: br,
  labelYearDropdown: pr
});
function Mr() {
  var e = "buttons", n = ir, t = mt, r = {}, a = {}, o = 1, i = {}, s = /* @__PURE__ */ new Date();
  return {
    captionLayout: e,
    classNames: n,
    formatters: hr,
    labels: _r,
    locale: t,
    modifiersClassNames: r,
    modifiers: a,
    numberOfMonths: o,
    styles: i,
    today: s,
    mode: "default"
  };
}
function Dr(e) {
  var n = e.fromYear, t = e.toYear, r = e.fromMonth, a = e.toMonth, o = e.fromDate, i = e.toDate;
  return r ? o = E(r) : n && (o = new Date(n, 0, 1)), a ? i = Be(a) : t && (i = new Date(t, 11, 31)), {
    fromDate: o ? ae(o) : void 0,
    toDate: i ? ae(i) : void 0
  };
}
var _t = J(void 0);
function kr(e) {
  var n, t = e.initialProps, r = Mr(), a = Dr(t), o = a.fromDate, i = a.toDate, s = (n = t.captionLayout) !== null && n !== void 0 ? n : r.captionLayout;
  s !== "buttons" && (!o || !i) && (s = "buttons");
  var l;
  (Me(t) || fe(t) || he(t)) && (l = t.onSelect);
  var d = w(w(w({}, r), t), { captionLayout: s, classNames: w(w({}, r.classNames), t.classNames), components: w({}, t.components), formatters: w(w({}, r.formatters), t.formatters), fromDate: o, labels: w(w({}, r.labels), t.labels), mode: t.mode || r.mode, modifiers: w(w({}, r.modifiers), t.modifiers), modifiersClassNames: w(w({}, r.modifiersClassNames), t.modifiersClassNames), onSelect: l, styles: w(w({}, r.styles), t.styles), toDate: i });
  return u(_t.Provider, { value: d, children: e.children });
}
function N() {
  var e = Z(_t);
  if (!e)
    throw new Error("useDayPicker must be used within a DayPickerProvider.");
  return e;
}
function Mt(e) {
  var n = N(), t = n.locale, r = n.classNames, a = n.styles, o = n.formatters.formatCaption;
  return u("div", { className: r.caption_label, style: a.caption_label, "aria-live": "polite", role: "presentation", id: e.id, children: o(e.displayMonth, { locale: t }) });
}
function Nr(e) {
  return u("svg", w({ width: "8px", height: "8px", viewBox: "0 0 120 120", "data-testid": "iconDropdown" }, e, { children: u("path", { d: "M4.22182541,48.2218254 C8.44222828,44.0014225 15.2388494,43.9273804 19.5496459,47.9996989 L19.7781746,48.2218254 L60,88.443 L100.221825,48.2218254 C104.442228,44.0014225 111.238849,43.9273804 115.549646,47.9996989 L115.778175,48.2218254 C119.998577,52.4422283 120.07262,59.2388494 116.000301,63.5496459 L115.778175,63.7781746 L67.7781746,111.778175 C63.5577717,115.998577 56.7611506,116.07262 52.4503541,112.000301 L52.2218254,111.778175 L4.22182541,63.7781746 C-0.0739418023,59.4824074 -0.0739418023,52.5175926 4.22182541,48.2218254 Z", fill: "currentColor", fillRule: "nonzero" }) }));
}
function Dt(e) {
  var n, t, r = e.onChange, a = e.value, o = e.children, i = e.caption, s = e.className, l = e.style, d = N(), c = (t = (n = d.components) === null || n === void 0 ? void 0 : n.IconDropdown) !== null && t !== void 0 ? t : Nr;
  return O("div", { className: s, style: l, children: [u("span", { className: d.classNames.vhidden, children: e["aria-label"] }), u("select", { name: e.name, "aria-label": e["aria-label"], className: d.classNames.dropdown, style: d.styles.dropdown, value: a, onChange: r, children: o }), O("div", { className: d.classNames.caption_label, style: d.styles.caption_label, "aria-hidden": "true", children: [i, u(c, { className: d.classNames.dropdown_icon, style: d.styles.dropdown_icon })] })] });
}
function xr(e) {
  var n, t = N(), r = t.fromDate, a = t.toDate, o = t.styles, i = t.locale, s = t.formatters.formatMonthCaption, l = t.classNames, d = t.components, c = t.labels.labelMonthDropdown;
  if (!r)
    return u(H, {});
  if (!a)
    return u(H, {});
  var f = [];
  if (ar(r, a))
    for (var y = E(r), m = r.getMonth(); m <= a.getMonth(); m++)
      f.push(Pe(y, m));
  else
    for (var y = E(/* @__PURE__ */ new Date()), m = 0; m <= 11; m++)
      f.push(Pe(y, m));
  var g = function(b) {
    var D = Number(b.target.value), p = Pe(E(e.displayMonth), D);
    e.onChange(p);
  }, _ = (n = d == null ? void 0 : d.Dropdown) !== null && n !== void 0 ? n : Dt;
  return u(_, { name: "months", "aria-label": c(), className: l.dropdown_month, style: o.dropdown_month, onChange: g, value: e.displayMonth.getMonth(), caption: s(e.displayMonth, { locale: i }), children: f.map(function(b) {
    return u("option", { value: b.getMonth(), children: s(b, { locale: i }) }, b.getMonth());
  }) });
}
function Cr(e) {
  var n, t = e.displayMonth, r = N(), a = r.fromDate, o = r.toDate, i = r.locale, s = r.styles, l = r.classNames, d = r.components, c = r.formatters.formatYearCaption, f = r.labels.labelYearDropdown, y = [];
  if (!a)
    return u(H, {});
  if (!o)
    return u(H, {});
  for (var m = a.getFullYear(), g = o.getFullYear(), _ = m; _ <= g; _++)
    y.push(at(ft(/* @__PURE__ */ new Date()), _));
  var b = function(p) {
    var x = at(E(t), Number(p.target.value));
    e.onChange(x);
  }, D = (n = d == null ? void 0 : d.Dropdown) !== null && n !== void 0 ? n : Dt;
  return u(D, { name: "years", "aria-label": f(), className: l.dropdown_year, style: s.dropdown_year, onChange: b, value: t.getFullYear(), caption: c(t, { locale: i }), children: y.map(function(p) {
    return u("option", { value: p.getFullYear(), children: c(p, { locale: i }) }, p.getFullYear());
  }) });
}
function Pr(e, n) {
  var t = I(e), r = t[0], a = t[1], o = n === void 0 ? r : n;
  return [o, a];
}
function Or(e) {
  var n = e.month, t = e.defaultMonth, r = e.today, a = n || t || r || /* @__PURE__ */ new Date(), o = e.toDate, i = e.fromDate, s = e.numberOfMonths, l = s === void 0 ? 1 : s;
  if (o && ce(o, a) < 0) {
    var d = -1 * (l - 1);
    a = B(o, d);
  }
  return i && ce(a, i) < 0 && (a = i), E(a);
}
function Sr() {
  var e = N(), n = Or(e), t = Pr(n, e.month), r = t[0], a = t[1], o = function(i) {
    var s;
    if (!e.disableNavigation) {
      var l = E(i);
      a(l), (s = e.onMonthChange) === null || s === void 0 || s.call(e, l);
    }
  };
  return [r, o];
}
function Wr(e, n) {
  for (var t = n.reverseMonths, r = n.numberOfMonths, a = E(e), o = E(B(a, r)), i = ce(o, a), s = [], l = 0; l < i; l++) {
    var d = B(a, l);
    s.push(d);
  }
  return t && (s = s.reverse()), s;
}
function Tr(e, n) {
  if (!n.disableNavigation) {
    var t = n.toDate, r = n.pagedNavigation, a = n.numberOfMonths, o = a === void 0 ? 1 : a, i = r ? o : 1, s = E(e);
    if (!t)
      return B(s, i);
    var l = ce(t, e);
    if (!(l < o))
      return B(s, i);
  }
}
function Yr(e, n) {
  if (!n.disableNavigation) {
    var t = n.fromDate, r = n.pagedNavigation, a = n.numberOfMonths, o = a === void 0 ? 1 : a, i = r ? o : 1, s = E(e);
    if (!t)
      return B(s, -i);
    var l = ce(s, t);
    if (!(l <= 0))
      return B(s, -i);
  }
}
var kt = J(void 0);
function Fr(e) {
  var n = N(), t = Sr(), r = t[0], a = t[1], o = Wr(r, n), i = Tr(r, n), s = Yr(r, n), l = function(f) {
    return o.some(function(y) {
      return qe(f, y);
    });
  }, d = function(f, y) {
    l(f) || (y && wt(f, y) ? a(B(f, 1 + n.numberOfMonths * -1)) : a(f));
  }, c = {
    currentMonth: r,
    displayMonths: o,
    goToMonth: a,
    goToDate: d,
    previousMonth: s,
    nextMonth: i,
    isDateDisplayed: l
  };
  return u(kt.Provider, { value: c, children: e.children });
}
function me() {
  var e = Z(kt);
  if (!e)
    throw new Error("useNavigation must be used within a NavigationProvider");
  return e;
}
function ot(e) {
  var n, t = N(), r = t.classNames, a = t.styles, o = t.components, i = me().goToMonth, s = function(c) {
    i(B(c, e.displayIndex ? -e.displayIndex : 0));
  }, l = (n = o == null ? void 0 : o.CaptionLabel) !== null && n !== void 0 ? n : Mt, d = u(l, { id: e.id, displayMonth: e.displayMonth });
  return O("div", { className: r.caption_dropdowns, style: a.caption_dropdowns, children: [u("div", { className: r.vhidden, children: d }), u(xr, { onChange: s, displayMonth: e.displayMonth }), u(Cr, { onChange: s, displayMonth: e.displayMonth })] });
}
function Lr(e) {
  return u("svg", w({ width: "16px", height: "16px", viewBox: "0 0 120 120" }, e, { children: u("path", { d: "M69.490332,3.34314575 C72.6145263,0.218951416 77.6798462,0.218951416 80.8040405,3.34314575 C83.8617626,6.40086786 83.9268205,11.3179931 80.9992143,14.4548388 L80.8040405,14.6568542 L35.461,60 L80.8040405,105.343146 C83.8617626,108.400868 83.9268205,113.317993 80.9992143,116.454839 L80.8040405,116.656854 C77.7463184,119.714576 72.8291931,119.779634 69.6923475,116.852028 L69.490332,116.656854 L18.490332,65.6568542 C15.4326099,62.5991321 15.367552,57.6820069 18.2951583,54.5451612 L18.490332,54.3431458 L69.490332,3.34314575 Z", fill: "currentColor", fillRule: "nonzero" }) }));
}
function Er(e) {
  return u("svg", w({ width: "16px", height: "16px", viewBox: "0 0 120 120" }, e, { children: u("path", { d: "M49.8040405,3.34314575 C46.6798462,0.218951416 41.6145263,0.218951416 38.490332,3.34314575 C35.4326099,6.40086786 35.367552,11.3179931 38.2951583,14.4548388 L38.490332,14.6568542 L83.8333725,60 L38.490332,105.343146 C35.4326099,108.400868 35.367552,113.317993 38.2951583,116.454839 L38.490332,116.656854 C41.5480541,119.714576 46.4651794,119.779634 49.602025,116.852028 L49.8040405,116.656854 L100.804041,65.6568542 C103.861763,62.5991321 103.926821,57.6820069 100.999214,54.5451612 L100.804041,54.3431458 L49.8040405,3.34314575 Z", fill: "currentColor" }) }));
}
var _e = St(function(e, n) {
  var t = N(), r = t.classNames, a = t.styles, o = [r.button_reset, r.button];
  e.className && o.push(e.className);
  var i = o.join(" "), s = w(w({}, a.button_reset), a.button);
  return e.style && Object.assign(s, e.style), u("button", w({}, e, { ref: n, type: "button", className: i, style: s }));
});
function Ir(e) {
  var n, t, r = N(), a = r.dir, o = r.locale, i = r.classNames, s = r.styles, l = r.labels, d = l.labelPrevious, c = l.labelNext, f = r.components;
  if (!e.nextMonth && !e.previousMonth)
    return u(H, {});
  var y = d(e.previousMonth, { locale: o }), m = [
    i.nav_button,
    i.nav_button_previous
  ].join(" "), g = c(e.nextMonth, { locale: o }), _ = [
    i.nav_button,
    i.nav_button_next
  ].join(" "), b = (n = f == null ? void 0 : f.IconRight) !== null && n !== void 0 ? n : Er, D = (t = f == null ? void 0 : f.IconLeft) !== null && t !== void 0 ? t : Lr;
  return O("div", { className: i.nav, style: s.nav, children: [!e.hidePrevious && u(_e, { name: "previous-month", "aria-label": y, className: m, style: s.nav_button_previous, disabled: !e.previousMonth, onClick: e.onPreviousClick, children: a === "rtl" ? u(b, { className: i.nav_icon, style: s.nav_icon }) : u(D, { className: i.nav_icon, style: s.nav_icon }) }), !e.hideNext && u(_e, { name: "next-month", "aria-label": g, className: _, style: s.nav_button_next, disabled: !e.nextMonth, onClick: e.onNextClick, children: a === "rtl" ? u(D, { className: i.nav_icon, style: s.nav_icon }) : u(b, { className: i.nav_icon, style: s.nav_icon }) })] });
}
function it(e) {
  var n = N().numberOfMonths, t = me(), r = t.previousMonth, a = t.nextMonth, o = t.goToMonth, i = t.displayMonths, s = i.findIndex(function(g) {
    return qe(e.displayMonth, g);
  }), l = s === 0, d = s === i.length - 1, c = n > 1 && (l || !d), f = n > 1 && (d || !l), y = function() {
    r && o(r);
  }, m = function() {
    a && o(a);
  };
  return u(Ir, { displayMonth: e.displayMonth, hideNext: c, hidePrevious: f, nextMonth: a, previousMonth: r, onPreviousClick: y, onNextClick: m });
}
function Rr(e) {
  var n, t = N(), r = t.classNames, a = t.disableNavigation, o = t.styles, i = t.captionLayout, s = t.components, l = (n = s == null ? void 0 : s.CaptionLabel) !== null && n !== void 0 ? n : Mt, d;
  return a ? d = u(l, { id: e.id, displayMonth: e.displayMonth }) : i === "dropdown" ? d = u(ot, { displayMonth: e.displayMonth, id: e.id }) : i === "dropdown-buttons" ? d = O(H, { children: [u(ot, { displayMonth: e.displayMonth, displayIndex: e.displayIndex, id: e.id }), u(it, { displayMonth: e.displayMonth, displayIndex: e.displayIndex, id: e.id })] }) : d = O(H, { children: [u(l, { id: e.id, displayMonth: e.displayMonth, displayIndex: e.displayIndex }), u(it, { displayMonth: e.displayMonth, id: e.id })] }), u("div", { className: r.caption, style: o.caption, children: d });
}
function jr(e) {
  var n = N(), t = n.footer, r = n.styles, a = n.classNames.tfoot;
  return t ? u("tfoot", { className: a, style: r.tfoot, children: u("tr", { children: u("td", { colSpan: 8, children: t }) }) }) : u(H, {});
}
function Hr(e, n, t) {
  for (var r = t ? U(/* @__PURE__ */ new Date()) : V(/* @__PURE__ */ new Date(), { locale: e, weekStartsOn: n }), a = [], o = 0; o < 7; o++) {
    var i = F(r, o);
    a.push(i);
  }
  return a;
}
function Br() {
  var e = N(), n = e.classNames, t = e.styles, r = e.showWeekNumber, a = e.locale, o = e.weekStartsOn, i = e.ISOWeek, s = e.formatters.formatWeekdayName, l = e.labels.labelWeekday, d = Hr(a, o, i);
  return O("tr", { style: t.head_row, className: n.head_row, children: [r && u("td", { style: t.head_cell, className: n.head_cell }), d.map(function(c, f) {
    return u("th", { scope: "col", className: n.head_cell, style: t.head_cell, "aria-label": l(c, { locale: a }), children: s(c, { locale: a }) }, f);
  })] });
}
function Ar() {
  var e, n = N(), t = n.classNames, r = n.styles, a = n.components, o = (e = a == null ? void 0 : a.HeadRow) !== null && e !== void 0 ? e : Br;
  return u("thead", { style: r.head, className: t.head, children: u(o, {}) });
}
function qr(e) {
  var n = N(), t = n.locale, r = n.formatters.formatDay;
  return u(H, { children: r(e.date, { locale: t }) });
}
var $e = J(void 0);
function $r(e) {
  if (!fe(e.initialProps)) {
    var n = {
      selected: void 0,
      modifiers: {
        disabled: []
      }
    };
    return u($e.Provider, { value: n, children: e.children });
  }
  return u(Vr, { initialProps: e.initialProps, children: e.children });
}
function Vr(e) {
  var n = e.initialProps, t = e.children, r = n.selected, a = n.min, o = n.max, i = function(d, c, f) {
    var y, m;
    (y = n.onDayClick) === null || y === void 0 || y.call(n, d, c, f);
    var g = !!(c.selected && a && (r == null ? void 0 : r.length) === a);
    if (!g) {
      var _ = !!(!c.selected && o && (r == null ? void 0 : r.length) === o);
      if (!_) {
        var b = r ? pt([], r, !0) : [];
        if (c.selected) {
          var D = b.findIndex(function(p) {
            return L(d, p);
          });
          b.splice(D, 1);
        } else
          b.push(d);
        (m = n.onSelect) === null || m === void 0 || m.call(n, b, d, c, f);
      }
    }
  }, s = {
    disabled: []
  };
  r && s.disabled.push(function(d) {
    var c = o && r.length > o - 1, f = r.some(function(y) {
      return L(y, d);
    });
    return !!(c && !f);
  });
  var l = {
    selected: r,
    onDayClick: i,
    modifiers: s
  };
  return u($e.Provider, { value: l, children: t });
}
function Ve() {
  var e = Z($e);
  if (!e)
    throw new Error("useSelectMultiple must be used within a SelectMultipleProvider");
  return e;
}
function Qr(e, n) {
  var t = n || {}, r = t.from, a = t.to;
  return r && a ? L(a, e) && L(r, e) ? void 0 : L(a, e) ? { from: a, to: void 0 } : L(r, e) ? void 0 : Fe(r, e) ? { from: e, to: a } : { from: r, to: e } : a ? Fe(e, a) ? { from: a, to: e } : { from: e, to: a } : r ? wt(e, r) ? { from: e, to: r } : { from: r, to: e } : { from: e, to: void 0 };
}
var Qe = J(void 0);
function Xr(e) {
  if (!he(e.initialProps)) {
    var n = {
      selected: void 0,
      modifiers: {
        range_start: [],
        range_end: [],
        range_middle: [],
        disabled: []
      }
    };
    return u(Qe.Provider, { value: n, children: e.children });
  }
  return u(Gr, { initialProps: e.initialProps, children: e.children });
}
function Gr(e) {
  var n = e.initialProps, t = e.children, r = n.selected, a = r || {}, o = a.from, i = a.to, s = n.min, l = n.max, d = function(m, g, _) {
    var b, D;
    (b = n.onDayClick) === null || b === void 0 || b.call(n, m, g, _);
    var p = Qr(m, r);
    (D = n.onSelect) === null || D === void 0 || D.call(n, p, m, g, _);
  }, c = {
    range_start: [],
    range_end: [],
    range_middle: [],
    disabled: []
  };
  if (o ? (c.range_start = [o], i ? (c.range_end = [i], L(o, i) || (c.range_middle = [
    {
      after: o,
      before: i
    }
  ])) : c.range_end = [o]) : i && (c.range_start = [i], c.range_end = [i]), s && (o && !i && c.disabled.push({
    after: Ce(o, s - 1),
    before: F(o, s - 1)
  }), o && i && c.disabled.push({
    after: o,
    before: F(o, s - 1)
  }), !o && i && c.disabled.push({
    after: Ce(i, s - 1),
    before: F(i, s - 1)
  })), l) {
    if (o && !i && (c.disabled.push({
      before: F(o, -l + 1)
    }), c.disabled.push({
      after: F(o, l - 1)
    })), o && i) {
      var f = $(i, o) + 1, y = l - f;
      c.disabled.push({
        before: Ce(o, y)
      }), c.disabled.push({
        after: F(i, y)
      });
    }
    !o && i && (c.disabled.push({
      before: F(i, -l + 1)
    }), c.disabled.push({
      after: F(i, l - 1)
    }));
  }
  return u(Qe.Provider, { value: { selected: r, onDayClick: d, modifiers: c }, children: t });
}
function Xe() {
  var e = Z(Qe);
  if (!e)
    throw new Error("useSelectRange must be used within a SelectRangeProvider");
  return e;
}
function we(e) {
  return Array.isArray(e) ? pt([], e, !0) : e !== void 0 ? [e] : [];
}
function zr(e) {
  var n = {};
  return Object.entries(e).forEach(function(t) {
    var r = t[0], a = t[1];
    n[r] = we(a);
  }), n;
}
var A;
(function(e) {
  e.Outside = "outside", e.Disabled = "disabled", e.Selected = "selected", e.Hidden = "hidden", e.Today = "today", e.RangeStart = "range_start", e.RangeEnd = "range_end", e.RangeMiddle = "range_middle";
})(A || (A = {}));
var Kr = A.Selected, X = A.Disabled, Ur = A.Hidden, Jr = A.Today, Oe = A.RangeEnd, Se = A.RangeMiddle, We = A.RangeStart, Zr = A.Outside;
function ea(e, n, t) {
  var r, a = (r = {}, r[Kr] = we(e.selected), r[X] = we(e.disabled), r[Ur] = we(e.hidden), r[Jr] = [e.today], r[Oe] = [], r[Se] = [], r[We] = [], r[Zr] = [], r);
  return e.fromDate && a[X].push({ before: e.fromDate }), e.toDate && a[X].push({ after: e.toDate }), fe(e) ? a[X] = a[X].concat(n.modifiers[X]) : he(e) && (a[X] = a[X].concat(t.modifiers[X]), a[We] = t.modifiers[We], a[Se] = t.modifiers[Se], a[Oe] = t.modifiers[Oe]), a;
}
var Nt = J(void 0);
function ta(e) {
  var n = N(), t = Ve(), r = Xe(), a = ea(n, t, r), o = zr(n.modifiers), i = w(w({}, a), o);
  return u(Nt.Provider, { value: i, children: e.children });
}
function xt() {
  var e = Z(Nt);
  if (!e)
    throw new Error("useModifiers must be used within a ModifiersProvider");
  return e;
}
function na(e) {
  return !!(e && typeof e == "object" && "before" in e && "after" in e);
}
function ra(e) {
  return !!(e && typeof e == "object" && "from" in e);
}
function aa(e) {
  return !!(e && typeof e == "object" && "after" in e);
}
function oa(e) {
  return !!(e && typeof e == "object" && "before" in e);
}
function ia(e) {
  return !!(e && typeof e == "object" && "dayOfWeek" in e);
}
function sa(e, n) {
  var t, r = n.from, a = n.to;
  if (r && a) {
    var o = $(a, r) < 0;
    o && (t = [a, r], r = t[0], a = t[1]);
    var i = $(e, r) >= 0 && $(a, e) >= 0;
    return i;
  }
  return a ? L(a, e) : r ? L(r, e) : !1;
}
function ua(e) {
  return He(e);
}
function la(e) {
  return Array.isArray(e) && e.every(He);
}
function ca(e, n) {
  return n.some(function(t) {
    if (typeof t == "boolean")
      return t;
    if (ua(t))
      return L(e, t);
    if (la(t))
      return t.includes(e);
    if (ra(t))
      return sa(e, t);
    if (ia(t))
      return t.dayOfWeek.includes(e.getDay());
    if (na(t)) {
      var r = $(t.before, e), a = $(t.after, e), o = r > 0, i = a < 0, s = Fe(t.before, t.after);
      return s ? i && o : o || i;
    }
    return aa(t) ? $(e, t.after) > 0 : oa(t) ? $(t.before, e) > 0 : typeof t == "function" ? t(e) : !1;
  });
}
function Ge(e, n, t) {
  var r = Object.keys(n).reduce(function(o, i) {
    var s = n[i];
    return ca(e, s) && o.push(i), o;
  }, []), a = {};
  return r.forEach(function(o) {
    return a[o] = !0;
  }), t && !qe(e, t) && (a.outside = !0), a;
}
function da(e, n) {
  for (var t = E(e[0]), r = Be(e[e.length - 1]), a, o, i = t; i <= r; ) {
    var s = Ge(i, n), l = !s.disabled && !s.hidden;
    if (!l) {
      i = F(i, 1);
      continue;
    }
    if (s.selected)
      return i;
    s.today && !o && (o = i), a || (a = i), i = F(i, 1);
  }
  return o || a;
}
var fa = 365;
function Ct(e, n) {
  var t = n.moveBy, r = n.direction, a = n.context, o = n.modifiers, i = n.retry, s = i === void 0 ? { count: 0, lastFocused: e } : i, l = a.weekStartsOn, d = a.fromDate, c = a.toDate, f = a.locale, y = {
    day: F,
    week: Ye,
    month: B,
    year: Zt,
    startOfWeek: function(b) {
      return a.ISOWeek ? U(b) : V(b, { locale: f, weekStartsOn: l });
    },
    endOfWeek: function(b) {
      return a.ISOWeek ? ht(b) : Ae(b, { locale: f, weekStartsOn: l });
    }
  }, m = y[t](e, r === "after" ? 1 : -1);
  r === "before" && d ? m = en([d, m]) : r === "after" && c && (m = tn([c, m]));
  var g = !0;
  if (o) {
    var _ = Ge(m, o);
    g = !_.disabled && !_.hidden;
  }
  return g ? m : s.count > fa ? s.lastFocused : Ct(m, {
    moveBy: t,
    direction: r,
    context: a,
    modifiers: o,
    retry: w(w({}, s), { count: s.count + 1 })
  });
}
var Pt = J(void 0);
function ha(e) {
  var n = me(), t = xt(), r = I(), a = r[0], o = r[1], i = I(), s = i[0], l = i[1], d = da(n.displayMonths, t), c = a ?? (s && n.isDateDisplayed(s)) ? s : d, f = function() {
    l(a), o(void 0);
  }, y = function(b) {
    o(b);
  }, m = N(), g = function(b, D) {
    if (a) {
      var p = Ct(a, {
        moveBy: b,
        direction: D,
        context: m,
        modifiers: t
      });
      L(a, p) || (n.goToDate(p, a), y(p));
    }
  }, _ = {
    focusedDay: a,
    focusTarget: c,
    blur: f,
    focus: y,
    focusDayAfter: function() {
      return g("day", "after");
    },
    focusDayBefore: function() {
      return g("day", "before");
    },
    focusWeekAfter: function() {
      return g("week", "after");
    },
    focusWeekBefore: function() {
      return g("week", "before");
    },
    focusMonthBefore: function() {
      return g("month", "before");
    },
    focusMonthAfter: function() {
      return g("month", "after");
    },
    focusYearBefore: function() {
      return g("year", "before");
    },
    focusYearAfter: function() {
      return g("year", "after");
    },
    focusStartOfWeek: function() {
      return g("startOfWeek", "before");
    },
    focusEndOfWeek: function() {
      return g("endOfWeek", "after");
    }
  };
  return u(Pt.Provider, { value: _, children: e.children });
}
function ze() {
  var e = Z(Pt);
  if (!e)
    throw new Error("useFocusContext must be used within a FocusProvider");
  return e;
}
function ma(e, n) {
  var t = xt(), r = Ge(e, t, n);
  return r;
}
var Ke = J(void 0);
function va(e) {
  if (!Me(e.initialProps)) {
    var n = {
      selected: void 0
    };
    return u(Ke.Provider, { value: n, children: e.children });
  }
  return u(ya, { initialProps: e.initialProps, children: e.children });
}
function ya(e) {
  var n = e.initialProps, t = e.children, r = function(o, i, s) {
    var l, d, c;
    if ((l = n.onDayClick) === null || l === void 0 || l.call(n, o, i, s), i.selected && !n.required) {
      (d = n.onSelect) === null || d === void 0 || d.call(n, void 0, o, i, s);
      return;
    }
    (c = n.onSelect) === null || c === void 0 || c.call(n, o, o, i, s);
  }, a = {
    selected: n.selected,
    onDayClick: r
  };
  return u(Ke.Provider, { value: a, children: t });
}
function Ot() {
  var e = Z(Ke);
  if (!e)
    throw new Error("useSelectSingle must be used within a SelectSingleProvider");
  return e;
}
function ga(e, n) {
  var t = N(), r = Ot(), a = Ve(), o = Xe(), i = ze(), s = i.focusDayAfter, l = i.focusDayBefore, d = i.focusWeekAfter, c = i.focusWeekBefore, f = i.blur, y = i.focus, m = i.focusMonthBefore, g = i.focusMonthAfter, _ = i.focusYearBefore, b = i.focusYearAfter, D = i.focusStartOfWeek, p = i.focusEndOfWeek, x = function(h) {
    var v, S, ne, T;
    Me(t) ? (v = r.onDayClick) === null || v === void 0 || v.call(r, e, n, h) : fe(t) ? (S = a.onDayClick) === null || S === void 0 || S.call(a, e, n, h) : he(t) ? (ne = o.onDayClick) === null || ne === void 0 || ne.call(o, e, n, h) : (T = t.onDayClick) === null || T === void 0 || T.call(t, e, n, h);
  }, q = function(h) {
    var v;
    y(e), (v = t.onDayFocus) === null || v === void 0 || v.call(t, e, n, h);
  }, C = function(h) {
    var v;
    f(), (v = t.onDayBlur) === null || v === void 0 || v.call(t, e, n, h);
  }, Q = function(h) {
    var v;
    (v = t.onDayMouseEnter) === null || v === void 0 || v.call(t, e, n, h);
  }, P = function(h) {
    var v;
    (v = t.onDayMouseLeave) === null || v === void 0 || v.call(t, e, n, h);
  }, ie = function(h) {
    var v;
    (v = t.onDayPointerEnter) === null || v === void 0 || v.call(t, e, n, h);
  }, ve = function(h) {
    var v;
    (v = t.onDayPointerLeave) === null || v === void 0 || v.call(t, e, n, h);
  }, ye = function(h) {
    var v;
    (v = t.onDayTouchCancel) === null || v === void 0 || v.call(t, e, n, h);
  }, j = function(h) {
    var v;
    (v = t.onDayTouchEnd) === null || v === void 0 || v.call(t, e, n, h);
  }, De = function(h) {
    var v;
    (v = t.onDayTouchMove) === null || v === void 0 || v.call(t, e, n, h);
  }, ke = function(h) {
    var v;
    (v = t.onDayTouchStart) === null || v === void 0 || v.call(t, e, n, h);
  }, Ne = function(h) {
    var v;
    (v = t.onDayKeyUp) === null || v === void 0 || v.call(t, e, n, h);
  }, ge = function(h) {
    var v;
    switch (h.key) {
      case "ArrowLeft":
        h.preventDefault(), h.stopPropagation(), t.dir === "rtl" ? s() : l();
        break;
      case "ArrowRight":
        h.preventDefault(), h.stopPropagation(), t.dir === "rtl" ? l() : s();
        break;
      case "ArrowDown":
        h.preventDefault(), h.stopPropagation(), d();
        break;
      case "ArrowUp":
        h.preventDefault(), h.stopPropagation(), c();
        break;
      case "PageUp":
        h.preventDefault(), h.stopPropagation(), h.shiftKey ? _() : m();
        break;
      case "PageDown":
        h.preventDefault(), h.stopPropagation(), h.shiftKey ? b() : g();
        break;
      case "Home":
        h.preventDefault(), h.stopPropagation(), D();
        break;
      case "End":
        h.preventDefault(), h.stopPropagation(), p();
        break;
    }
    (v = t.onDayKeyDown) === null || v === void 0 || v.call(t, e, n, h);
  }, te = {
    onClick: x,
    onFocus: q,
    onBlur: C,
    onKeyDown: ge,
    onKeyUp: Ne,
    onMouseEnter: Q,
    onMouseLeave: P,
    onPointerEnter: ie,
    onPointerLeave: ve,
    onTouchCancel: ye,
    onTouchEnd: j,
    onTouchMove: De,
    onTouchStart: ke
  };
  return te;
}
function ba() {
  var e = N(), n = Ot(), t = Ve(), r = Xe(), a = Me(e) ? n.selected : fe(e) ? t.selected : he(e) ? r.selected : void 0;
  return a;
}
function wa(e) {
  return Object.values(A).includes(e);
}
function pa(e, n) {
  var t = [e.classNames.day];
  return Object.keys(n).forEach(function(r) {
    var a = e.modifiersClassNames[r];
    if (a)
      t.push(a);
    else if (wa(r)) {
      var o = e.classNames["day_".concat(r)];
      o && t.push(o);
    }
  }), t;
}
function _a(e, n) {
  var t = w({}, e.styles.day);
  return Object.keys(n).forEach(function(r) {
    var a;
    t = w(w({}, t), (a = e.modifiersStyles) === null || a === void 0 ? void 0 : a[r]);
  }), t;
}
function Ma(e, n, t) {
  var r, a, o, i = N(), s = ze(), l = ma(e, n), d = ga(e, l), c = ba(), f = !!(i.onDayClick || i.mode !== "default");
  oe(function() {
    var Q;
    l.outside || s.focusedDay && f && L(s.focusedDay, e) && ((Q = t.current) === null || Q === void 0 || Q.focus());
  }, [
    s.focusedDay,
    e,
    t,
    f,
    l.outside
  ]);
  var y = pa(i, l).join(" "), m = _a(i, l), g = !!(l.outside && !i.showOutsideDays || l.hidden), _ = (o = (a = i.components) === null || a === void 0 ? void 0 : a.DayContent) !== null && o !== void 0 ? o : qr, b = u(_, { date: e, displayMonth: n, activeModifiers: l }), D = {
    style: m,
    className: y,
    children: b,
    role: "gridcell"
  }, p = s.focusTarget && L(s.focusTarget, e) && !l.outside, x = s.focusedDay && L(s.focusedDay, e), q = w(w(w({}, D), (r = { disabled: l.disabled, role: "gridcell" }, r["aria-selected"] = l.selected, r.tabIndex = x || p ? 0 : -1, r)), d), C = {
    isButton: f,
    isHidden: g,
    activeModifiers: l,
    selectedDays: c,
    buttonProps: q,
    divProps: D
  };
  return C;
}
function Da(e) {
  var n = Tt(null), t = Ma(e.date, e.displayMonth, n);
  return t.isHidden ? u("div", { role: "gridcell" }) : t.isButton ? u(_e, w({ name: "day", ref: n }, t.buttonProps)) : u("div", w({}, t.divProps));
}
function ka(e) {
  var n = e.number, t = e.dates, r = N(), a = r.onWeekNumberClick, o = r.styles, i = r.classNames, s = r.locale, l = r.labels.labelWeekNumber, d = r.formatters.formatWeekNumber, c = d(Number(n), { locale: s });
  if (!a)
    return u("span", { className: i.weeknumber, style: o.weeknumber, children: c });
  var f = l(Number(n), { locale: s }), y = function(m) {
    a(n, t, m);
  };
  return u(_e, { name: "week-number", "aria-label": f, className: i.weeknumber, style: o.weeknumber, onClick: y, children: c });
}
function Na(e) {
  var n, t, r = N(), a = r.styles, o = r.classNames, i = r.showWeekNumber, s = r.components, l = (n = s == null ? void 0 : s.Day) !== null && n !== void 0 ? n : Da, d = (t = s == null ? void 0 : s.WeekNumber) !== null && t !== void 0 ? t : ka, c;
  return i && (c = u("td", { className: o.cell, style: a.cell, children: u(d, { number: e.weekNumber, dates: e.dates }) })), O("tr", { className: o.row, style: a.row, children: [c, e.dates.map(function(f) {
    return u("td", { className: o.cell, style: a.cell, role: "presentation", children: u(l, { displayMonth: e.displayMonth, date: f }) }, tr(f));
  })] });
}
function st(e, n, t) {
  for (var r = t != null && t.ISOWeek ? ht(n) : Ae(n, t), a = t != null && t.ISOWeek ? U(e) : V(e, t), o = $(r, a), i = [], s = 0; s <= o; s++)
    i.push(F(a, s));
  var l = i.reduce(function(d, c) {
    var f = t != null && t.ISOWeek ? vt(c) : gt(c, t), y = d.find(function(m) {
      return m.weekNumber === f;
    });
    return y ? (y.dates.push(c), d) : (d.push({
      weekNumber: f,
      dates: [c]
    }), d);
  }, []);
  return l;
}
function xa(e, n) {
  var t = st(E(e), Be(e), n);
  if (n != null && n.useFixedWeeks) {
    var r = rr(e, n);
    if (r < 6) {
      var a = t[t.length - 1], o = a.dates[a.dates.length - 1], i = Ye(o, 6 - r), s = st(Ye(o, 1), i, n);
      t.push.apply(t, s);
    }
  }
  return t;
}
function Ca(e) {
  var n, t, r, a = N(), o = a.locale, i = a.classNames, s = a.styles, l = a.hideHead, d = a.fixedWeeks, c = a.components, f = a.weekStartsOn, y = a.firstWeekContainsDate, m = a.ISOWeek, g = xa(e.displayMonth, {
    useFixedWeeks: !!d,
    ISOWeek: m,
    locale: o,
    weekStartsOn: f,
    firstWeekContainsDate: y
  }), _ = (n = c == null ? void 0 : c.Head) !== null && n !== void 0 ? n : Ar, b = (t = c == null ? void 0 : c.Row) !== null && t !== void 0 ? t : Na, D = (r = c == null ? void 0 : c.Footer) !== null && r !== void 0 ? r : jr;
  return O("table", { id: e.id, className: i.table, style: s.table, role: "grid", "aria-labelledby": e["aria-labelledby"], children: [!l && u(_, {}), u("tbody", { className: i.tbody, style: s.tbody, children: g.map(function(p) {
    return u(b, { displayMonth: e.displayMonth, dates: p.dates, weekNumber: p.weekNumber }, p.weekNumber);
  }) }), u(D, { displayMonth: e.displayMonth })] });
}
function Pa() {
  return !!(typeof window < "u" && window.document && window.document.createElement);
}
var Oa = Pa() ? Wt : oe, Te = !1, Sa = 0;
function ut() {
  return "react-day-picker-".concat(++Sa);
}
function Wa(e) {
  var n, t = e ?? (Te ? ut() : null), r = I(t), a = r[0], o = r[1];
  return Oa(function() {
    a === null && o(ut());
  }, []), oe(function() {
    Te === !1 && (Te = !0);
  }, []), (n = e ?? a) !== null && n !== void 0 ? n : void 0;
}
function Ta(e) {
  var n, t, r = N(), a = r.dir, o = r.classNames, i = r.styles, s = r.components, l = me().displayMonths, d = Wa(r.id ? "".concat(r.id, "-").concat(e.displayIndex) : void 0), c = r.id ? "".concat(r.id, "-grid-").concat(e.displayIndex) : void 0, f = [o.month], y = i.month, m = e.displayIndex === 0, g = e.displayIndex === l.length - 1, _ = !m && !g;
  a === "rtl" && (n = [m, g], g = n[0], m = n[1]), m && (f.push(o.caption_start), y = w(w({}, y), i.caption_start)), g && (f.push(o.caption_end), y = w(w({}, y), i.caption_end)), _ && (f.push(o.caption_between), y = w(w({}, y), i.caption_between));
  var b = (t = s == null ? void 0 : s.Caption) !== null && t !== void 0 ? t : Rr;
  return O("div", { className: f.join(" "), style: y, children: [u(b, { id: d, displayMonth: e.displayMonth, displayIndex: e.displayIndex }), u(Ca, { id: c, "aria-labelledby": d, displayMonth: e.displayMonth })] }, e.displayIndex);
}
function Ya(e) {
  var n = N(), t = n.classNames, r = n.styles;
  return u("div", { className: t.months, style: r.months, children: e.children });
}
function Fa(e) {
  var n, t, r = e.initialProps, a = N(), o = ze(), i = me(), s = I(!1), l = s[0], d = s[1];
  oe(function() {
    a.initialFocus && o.focusTarget && (l || (o.focus(o.focusTarget), d(!0)));
  }, [
    a.initialFocus,
    l,
    o.focus,
    o.focusTarget,
    o
  ]);
  var c = [a.classNames.root, a.className];
  a.numberOfMonths > 1 && c.push(a.classNames.multiple_months), a.showWeekNumber && c.push(a.classNames.with_weeknumber);
  var f = w(w({}, a.styles.root), a.style), y = Object.keys(r).filter(function(g) {
    return g.startsWith("data-");
  }).reduce(function(g, _) {
    var b;
    return w(w({}, g), (b = {}, b[_] = r[_], b));
  }, {}), m = (t = (n = r.components) === null || n === void 0 ? void 0 : n.Months) !== null && t !== void 0 ? t : Ya;
  return u("div", w({ className: c.join(" "), style: f, dir: a.dir, id: a.id, nonce: r.nonce, title: r.title, lang: r.lang }, y, { children: u(m, { children: i.displayMonths.map(function(g, _) {
    return u(Ta, { displayIndex: _, displayMonth: g }, _);
  }) }) }));
}
function La(e) {
  var n = e.children, t = or(e, ["children"]);
  return u(kr, { initialProps: t, children: u(Fr, { children: u(va, { initialProps: t, children: u($r, { initialProps: t, children: u(Xr, { initialProps: t, children: u(ta, { children: u(ha, { children: n }) }) }) }) }) }) });
}
function Ea(e) {
  return u(La, w({}, e, { children: u(Fa, { initialProps: e }) }));
}
var Ue = {}, Ia = Ee;
Object.defineProperty(Ue, "__esModule", {
  value: !0
});
var G = Ue.default = void 0, Ra = Ia(Ie()), ja = Le, Ha = (0, Ra.default)(/* @__PURE__ */ (0, ja.jsx)("path", {
  d: "M8.12 9.29 12 13.17l3.88-3.88c.39-.39 1.02-.39 1.41 0 .39.39.39 1.02 0 1.41l-4.59 4.59c-.39.39-1.02.39-1.41 0L6.7 10.7a.9959.9959 0 0 1 0-1.41c.39-.38 1.03-.39 1.42 0z"
}), "KeyboardArrowDownRounded");
G = Ue.default = Ha;
var Je = {}, Ba = Ee;
Object.defineProperty(Je, "__esModule", {
  value: !0
});
var le = Je.default = void 0, Aa = Ba(Ie()), qa = Le, $a = (0, Aa.default)(/* @__PURE__ */ (0, qa.jsx)("path", {
  d: "M8.12 14.71 12 10.83l3.88 3.88c.39.39 1.02.39 1.41 0 .39-.39.39-1.02 0-1.41L12.7 8.71a.9959.9959 0 0 0-1.41 0L6.7 13.3c-.39.39-.39 1.02 0 1.41.39.38 1.03.39 1.42 0z"
}), "KeyboardArrowUpRounded");
le = Je.default = $a;
const Va = [
  { label: "3 Hour", value: "3hour" },
  { label: "12 Hour", value: "12hour" },
  { label: "1 Day", value: "1day" },
  { label: "1 Week", value: "7day" },
  { label: "1 Month", value: "1month" }
], Qa = Array.from({ length: 12 }, (e, n) => n).map((e) => e < 9 ? { label: `0${e + 1}`, value: `${e + 1}` } : { label: `${e + 1}`, value: `${e + 1}` }), Xa = Array.from({ length: 60 }, (e, n) => n).map((e) => e < 10 ? { label: `0${e}`, value: `${e}` } : { label: `${e}`, value: `${e}` });
function io({
  startTime: e,
  endTime: n,
  minTime: t = 0,
  maxTime: r = 1 / 0,
  options: a = Va,
  hideTime: o = !1,
  hideSeek: i = !1,
  hideRange: s = !1,
  interval: l = "7day",
  rangesOnly: d = !1,
  timezone: c = Y.tz.guess(),
  deltaChangePercent: f = 0.5,
  onChange: y,
  style: m
}) {
  const [g, _] = I(e ?? Y().subtract(7, "days").unix()), [b, D] = I(n ?? Y().unix()), [p, x] = I(a.some((h) => h.value === l) ? l : "custom"), q = Yt(() => Math.floor((b - g) * f), [b, g, f]);
  oe(() => {
    y && y(g, b, p);
  }, [g, b, p, y]);
  const [C, Q] = I(null), [P, ie] = I(null), ve = Y.unix(g), ye = Y.unix(b), j = p === "custom";
  function De(h) {
    if (x(h), h === "custom")
      return;
    const v = Ft(h);
    t && v.startTime < t || r && v.endTime > r || (_(v.startTime), D(v.endTime));
  }
  function ke(h) {
    const v = Y.unix((C == null ? void 0 : C.id) === "startTime" ? g : b), S = Y(h).tz(c);
    if (S.set("hour", v.hour()), S.set("minute", v.minute()), S.set("second", 0), (C == null ? void 0 : C.id) === "startTime") {
      if (S.unix() > b)
        return;
      _(S.unix());
    } else if ((C == null ? void 0 : C.id) === "endTime") {
      if (S.unix() < g)
        return;
      D(S.unix());
    }
  }
  const Ne = (h) => {
    const [v, S, ne] = [...h.split(" ")[0].split(":"), h.split(" ")[1]], T = Y.unix((P == null ? void 0 : P.id) === "startTime" ? g : b).tz(c);
    if (T.set("hour", +v), T.set("minute", +S), T.set("second", 0), ne === "PM" && +v < 12 ? T.set("hour", +v + 12) : ne === "AM" && +v == 12 && T.set("hour", 0), (P == null ? void 0 : P.id) === "startTime") {
      if (T.unix() > b || T.unix() < t)
        return;
      _(T.unix());
    } else if ((P == null ? void 0 : P.id) === "endTime") {
      if (T.unix() < g || T.unix() > r)
        return;
      D(T.unix());
    }
  }, ge = (h, v) => {
    const S = v * h;
    h === 1 && b + S > Y().unix() || h === -1 && g + S < t || h === 1 && b + S > r || (_(g + S), D(b + S));
  }, te = j ? `${W.date}` : `${W.date} ${W.nohover}`;
  return /* @__PURE__ */ O("div", { className: W.container, style: { ...m }, children: [
    !s && /* @__PURE__ */ O("div", { className: W.hover, children: [
      /* @__PURE__ */ O("div", { className: W.range, children: [
        /* @__PURE__ */ u(ct, { sx: { color: (m == null ? void 0 : m.color) || "#7A7A7A" } }),
        /* @__PURE__ */ u(be, { id: "range-select", style: { ...m }, options: [...a, { label: "Custom", value: "custom" }], value: p, onChange: De })
      ] }),
      !d && /* @__PURE__ */ u(Lt, { orientation: "vertical", flexItem: !0 })
    ] }),
    d ? null : /* @__PURE__ */ O(H, { children: [
      !i && /* @__PURE__ */ u(lt, { direction: -1, distance: q, onSeek: ge, color: (m == null ? void 0 : m.color) || "#7A7A7A" }),
      /* @__PURE__ */ u("div", { className: W.hover, children: /* @__PURE__ */ O("span", { className: te, id: "startTime", onClick: (h) => Q(j ? h.currentTarget : null), children: [
        ve.tz(c).format("MMM DD, YYYY"),
        j ? C ? C.id === "startTime" ? /* @__PURE__ */ u(le, {}) : /* @__PURE__ */ u(G, {}) : /* @__PURE__ */ u(G, {}) : null
      ] }) }),
      !o && /* @__PURE__ */ u("div", { className: W.hover, children: /* @__PURE__ */ O("span", { className: te, id: "startTime", onClick: (h) => ie(j ? h.currentTarget : null), children: [
        ve.tz(c).format("hh:mm A"),
        j ? P ? P.id === "startTime" ? /* @__PURE__ */ u(le, {}) : /* @__PURE__ */ u(G, {}) : /* @__PURE__ */ u(G, {}) : null
      ] }) }),
      /* @__PURE__ */ u("span", { className: W.toText, children: "to" }),
      /* @__PURE__ */ u("div", { className: W.hover, children: /* @__PURE__ */ O("span", { className: te, id: "endTime", onClick: (h) => Q(j ? h.currentTarget : null), children: [
        ye.tz(c).format("MMM DD, YYYY"),
        j ? C ? C.id === "endTime" ? /* @__PURE__ */ u(le, {}) : /* @__PURE__ */ u(G, {}) : /* @__PURE__ */ u(G, {}) : null
      ] }) }),
      !o && /* @__PURE__ */ u("div", { className: W.hover, children: /* @__PURE__ */ O("span", { className: te, id: "endTime", onClick: (h) => ie(j ? h.currentTarget : null), children: [
        ye.tz(c).format("hh:mm A"),
        j ? P ? P.id === "endTime" ? /* @__PURE__ */ u(le, {}) : /* @__PURE__ */ u(G, {}) : /* @__PURE__ */ u(G, {}) : null
      ] }) }),
      !i && /* @__PURE__ */ u(lt, { direction: 1, distance: q, onSeek: ge, color: m == null ? void 0 : m.color }),
      j && /* @__PURE__ */ O(H, { children: [
        /* @__PURE__ */ u(Ze, { className: W.menu, open: !!C, anchorEl: C, onClose: () => Q(null), children: /* @__PURE__ */ u(
          Ga,
          {
            value: Y.unix((C == null ? void 0 : C.id) === "startTime" ? g : b).toDate(),
            onSelect: ke,
            minTime: t,
            maxTime: r
          }
        ) }),
        /* @__PURE__ */ u(Ze, { className: W.menu, open: !!P, anchorEl: P, onClose: () => ie(null), children: /* @__PURE__ */ u(
          za,
          {
            value: Y.unix((P == null ? void 0 : P.id) === "startTime" ? g : b).tz(c).format("hh:mm A"),
            onChange: Ne
          }
        ) })
      ] })
    ] })
  ] });
}
function Ga({ value: e, onSelect: n, minTime: t, maxTime: r }) {
  return /* @__PURE__ */ u(H, { children: /* @__PURE__ */ u(
    Ea,
    {
      mode: "single",
      disabled: {
        before: t && t > 0 ? Y.unix(t).toDate() : void 0,
        after: r && r !== 1 / 0 ? Y.unix(r).toDate() : Y().toDate()
      },
      defaultMonth: e,
      selected: e,
      onSelect: (a) => {
        a && n(a);
      }
    }
  ) });
}
function za({ value: e, onChange: n }) {
  const [t, r] = I(+e.split(":")[0]), [a, o] = I(+e.split(":")[1].split(" ")[0]), [i, s] = I(e.split(" ")[1].toLowerCase());
  return oe(() => {
    const l = `${t}:${a} ${i.toUpperCase()}`;
    n(l);
  }, [t, a, i, n, e]), /* @__PURE__ */ O("div", { className: `${W.time} ${W.selector}`, children: [
    /* @__PURE__ */ u(be, { label: "Hour", value: t.toString(), options: Qa, onChange: (l) => r(+l) }),
    /* @__PURE__ */ u(be, { label: "Minute", value: a.toString(), options: Xa, onChange: (l) => o(+l) }),
    /* @__PURE__ */ u(be, { label: "AM/PM", value: i, options: [{ label: "AM", value: "am" }, { label: "PM", value: "pm" }], onChange: (l) => s(l) })
  ] });
}
function lt({ direction: e, distance: n, color: t, onSeek: r }) {
  return /* @__PURE__ */ u(
    "span",
    {
      className: W.seek,
      title: `Seek ${Y.duration(n, "seconds").humanize()} ${e === -1 ? "backward" : "forward"}`,
      onClick: () => r(e, n),
      children: /* @__PURE__ */ u(Et, { direction: e, color: t })
    }
  );
}
export {
  io as default
};
