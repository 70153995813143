import "./assets/style2.css";
import { e as r } from "./styled-40300750.js";
const i = "exact-prop: ​";
function j(e) {
  return process.env.NODE_ENV === "production" ? e : r({}, e, {
    [i]: (t) => {
      const n = Object.keys(t).filter((o) => !e.hasOwnProperty(o));
      return n.length > 0 ? new Error(`The following props are not supported: ${n.map((o) => `\`${o}\``).join(", ")}. Please remove them.`) : null;
    }
  });
}
function _(e, t) {
  return _ = Object.setPrototypeOf ? Object.setPrototypeOf.bind() : function(o, s) {
    return o.__proto__ = s, o;
  }, _(e, t);
}
function w(e, t) {
  e.prototype = Object.create(t.prototype), e.prototype.constructor = e, _(e, t);
}
const c = "_value_1dse4_3", p = "_valueText_1dse4_42", u = "_label_1dse4_47", l = "_multiChips_1dse4_57", d = "_chip_1dse4_64", a = "_menu_1dse4_80", f = "_options_1dse4_84", v = "_input_1dse4_101", m = "_error_1dse4_129", h = "_disabled_1dse4_133", O = "_group_1dse4_142", b = "_option_1dse4_84", y = "_selected_1dse4_169", P = "_icon_1dse4_173", E = {
  value: c,
  "value-outlined": "_value-outlined_1dse4_19",
  "value-filled": "_value-filled_1dse4_27",
  "value-none": "_value-none_1dse4_35",
  valueText: p,
  label: u,
  multiChips: l,
  chip: d,
  menu: a,
  options: f,
  input: v,
  error: m,
  disabled: h,
  group: O,
  option: b,
  selected: y,
  icon: P
};
export {
  w as _,
  j as e,
  E as s
};
